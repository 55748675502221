import { ConvertScutiForm } from '@/components/Form/ConvertScutiForm';
import { WalletDisabled } from './WalletDisabled';
import { Icon } from '@/components/Common/Icon';
import { NavLink } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { appSessionStorage } from '@/helpers/storage.helper';

export function WalletPage() {
  const gameUserId = appSessionStorage.getItem('gameUserId');
  const { game } = useStoreInfo();

  const canExchange = !!gameUserId && !!game.currency;

  return (
    <div className="flex flex-col items-center space-y-20 pt-4 md:pt-8">
      <div className="flex w-full justify-between">
        <div />
        <h4>Convert In Game Currency</h4>
        <NavLink to={appRoutes.transactions}>
          <Icon name="History" className="h-6 cursor-pointer text-white" />
        </NavLink>
      </div>
      {canExchange && <ConvertScutiForm className="joyride-exchange mt-4 w-full" />}
      {!canExchange && (
        <div className="joyride-exchange flex h-full flex-col items-center justify-center">
          <WalletDisabled />
        </div>
      )}
    </div>
  );
}
