import { Icon } from '../Icon';
import { useMenuItems } from './UserMenu.hooks';
import { appRoutes } from '@/helpers/routes.helper';
import { NavLink } from 'react-router-dom';
import { useMe } from '@/hooks/useMe';
import { useLogOut } from '@/hooks/useLogout';
import { useZendesk } from '@/hooks/useZendesk';
import { appSessionStorage } from '@/helpers/storage.helper';

interface UserMenuDrawerProps {
  className?: string;
  onClose: () => void;
}

export function UserMenuDrawer({ className, onClose }: UserMenuDrawerProps) {
  const me = useMe();
  const menuItems = useMenuItems(!!me);

  const handleLogOut = useLogOut();

  // Zendesk does not work for UWP
  const platform = appSessionStorage.getItem('platform');
  const disableHelpCenter = platform === 'Vuplex';

  const showHelper = useZendesk(disableHelpCenter);

  return (
    <div className={`fixed bottom-0 left-0 right-0 top-0 z-30 ${className}`}>
      <div>
        <div className="fixed bottom-0 left-0 right-0 top-0  z-10 w-screen bg-black opacity-50" onClick={onClose} />
        <div
          className="fixed right-0 top-0 z-30 m-2 flex h-12 w-12 cursor-pointer items-center justify-center rounded-full bg-dark-gray"
          onClick={onClose}
        >
          <Icon name="Cross" className="h-4 w-4 text-white" />
        </div>
      </div>
      <div className="fixed bottom-0 left-0 right-0 top-0 z-10 flex h-full w-[286px] flex-col overflow-y-auto bg-black">
        <div className="mt-10 px-4">
          <Icon name="ScutiLogo" className="h-8 w-auto shrink-0" />
        </div>
        {!!me && (
          <>
            <div className="mt-5 flex w-full px-4 pt-4">
              <Icon name="Avatar" className="mr-4 h-12" />
              <div>
                <div className="text-base  text-light-gray">Welcome back</div>
                <div className="text-lg font-medium text-white">{me.fullName}</div>
              </div>
            </div>
          </>
        )}
        <div className="mx-4 mt-10 h-[1px] bg-dark-gray" />
        <div className="flex-1">
          {menuItems.map((item) => (
            <NavLink
              to={item.route}
              key={item.label}
              onClick={onClose}
              className="flex cursor-pointer items-center px-5 py-4 hover:bg-blue hover:opacity-100"
            >
              <Icon name={item.icon} className="mr-5 h-6 pb-[2px] text-white" />
              <div className="text-sm font-medium text-white">{item.label}</div>
            </NavLink>
          ))}
        </div>
        <div className="pb-4">
          <div className="mx-4 mb-5 mt-10 h-[1px] bg-dark-gray" />
          {!disableHelpCenter && (
            <div className="flex items-center px-5 py-4" onClick={showHelper}>
              <Icon name="HelpCenter" className="mr-5 h-6 pb-[2px]" />
              <div className="text-sm font-medium text-white">Help Center</div>
            </div>
          )}
          {!!me ? (
            <div className="flex items-center px-5 py-4  hover:bg-blue hover:opacity-100" onClick={handleLogOut}>
              <Icon name="Logout" className="mr-5 h-6 pb-[2px]" />
              <div className="text-sm font-medium text-white">Log Out</div>
            </div>
          ) : (
            <NavLink className="flex items-center px-5 py-4  hover:bg-blue hover:opacity-100" to={appRoutes.login}>
              <Icon name="Logout" className="mr-5 h-6 pb-[2px] text-white" />
              <div className="text-sm font-medium text-white">Log In</div>
            </NavLink>
          )}
          <div className="flex items-center px-4 py-4">
            <div className="text-sm font-medium text-light-gray">App Version 1.09</div>
          </div>
        </div>
      </div>
    </div>
  );
}
