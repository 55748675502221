import { Tab } from '@headlessui/react';
import { useNavigate } from 'react-router-dom';

type TabItem = {
  label: string;
  selected: boolean;
  route: string;
};

export type TabsProps = {
  items: TabItem[];
};

export function Tabs({ items }: TabsProps) {
  const navigate = useNavigate();

  return (
    <Tab.Group>
      <Tab.List className="flex flex-row space-x-5">
        {items.map((item) => (
          <Tab
            key={item.label}
            className={`tab pb-3 ${
              item.selected ? 'border-blue font-semibold text-white' : 'border-transparent font-medium text-light-gray'
            } border-b-3 focus-visible:outline-none focus-visible:ring-0`}
            onClick={() => navigate(item.route)}
          >
            {item.label}
          </Tab>
        ))}
      </Tab.List>
    </Tab.Group>
  );
}
