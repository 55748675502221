import jwtDecode from 'jwt-decode';
import { appLocalStorage } from './storage.helper';
import { clearSession } from '@/utils/session.utils';

type Tokens = { token?: string | null; refreshToken?: string | null };

class FreshToken {
  private promise?: Promise<string | undefined> = undefined;
  private token?: string | null;
  private refreshToken?: string | null;

  setTokens({ token, refreshToken }: Tokens) {
    this.token = token;
    this.refreshToken = refreshToken;
  }

  private async fetchToken(): Promise<string | undefined> {
    if (!this.refreshToken) return;

    try {
      const response = await window.fetch(`${process.env.REACT_APP_API_URL}/auth/refresh`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${this.refreshToken}`,
        },
        body: '{}', // when content-type is set to app/json, body must not be empty
      });

      if (response?.ok) {
        return (await response.json()).token as string;
      } else {
        throw new Error('Forbidden');
      }
    } catch {
      clearSession();
    }

    return;
  }

  private isExpired() {
    const unixTime = Math.round(Date.now()) / 1000;

    return !this.token || jwtDecode<{ exp: number }>(this.token).exp < unixTime;
  }

  async getToken(): Promise<Tokens['token']> {
    if (!this.isExpired()) return this.token;
    if (!this.promise && this.refreshToken) this.promise = this.fetchToken();

    return this.promise;
  }

  hasRefreshToken(): boolean {
    return !!this.refreshToken;
  }
}

const freshToken = new FreshToken();

freshToken.setTokens({ refreshToken: appLocalStorage.getItem('refreshToken') });

export { freshToken };
