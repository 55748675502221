import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { NameAgeFormData, schema } from './NameAgeForm.schema';
import { Button } from '@/components/Common/Button';
import { SelectInput } from '@/components/Common/SelectInput';
import { genderOptions, getOptionByValue } from '@/utils/select-options.utils';
import { Input } from '@/components/Common/Input';
import { MaskedInput } from '@/components/Common/MaskedInput';
import { useNameAgeForm } from './NameAgeForm.hooks';
import { useMe } from '@/hooks/useMe';

export type RegistrationFormProps = {
  className?: string;
};

const getBirthDate = (birth?: string) => {
  if (!birth) return { date: '', year: '' };
  const [year, month, date] = birth.split('-');
  return { date: `${month}/${date}`, year };
};

export function NameAgeForm({ className }: RegistrationFormProps) {
  const me = useMe()!;
  const dateTruncated = !!me.personalInfo?.dateTruncated;

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<NameAgeFormData>({
    resolver: yupResolver(schema),
    defaultValues: {
      fullName: me?.fullName || '',
      birthDate: dateTruncated ? '' : getBirthDate(me?.personalInfo?.birthDate).date,
      birthYear: parseInt(getBirthDate(me?.personalInfo?.birthDate).year, 10) || undefined,
      gender: me?.personalInfo?.gender || '',
    },
    mode: 'all',
  });

  const submitForm = useNameAgeForm();

  return (
    <>
      <form noValidate className={className} onSubmit={handleSubmit(submitForm)}>
        <div className="flex flex-col space-y-4">
          <Controller
            control={control}
            name="fullName"
            render={({ field }) => (
              <Input
                label="Full Name"
                placeholder="Your full name"
                required
                {...field}
                error={errors.fullName?.message}
              />
            )}
          />
          <Controller
            control={control}
            name="birthDate"
            render={({ field }) => (
              <MaskedInput
                label="Date of Birth (Optional)"
                placeholder="MM/DD"
                value={dateTruncated ? '' : field.value}
                onChange={field.onChange}
                error={errors.birthDate?.message}
                options={{ date: true, datePattern: ['m', 'd'] }}
              />
            )}
          />
          <Controller
            control={control}
            name="birthYear"
            render={({ field }) => (
              <MaskedInput
                label="Year of Birth"
                required
                placeholder="YYYY"
                value={`${field.value || ''}`}
                onChange={field.onChange}
                error={errors.birthYear?.message}
                options={{ date: true, datePattern: ['Y'] }}
              />
            )}
          />
          <Controller
            control={control}
            name="gender"
            render={({ field }) => (
              <SelectInput
                label="Gender"
                required
                options={genderOptions}
                placeholder="Select a Gender"
                tooltip="This information helps us curate products for you"
                value={getOptionByValue(field.value, genderOptions)}
                onChange={field.onChange}
                error={errors.gender?.message}
              />
            )}
          />
        </div>
        <div className="py-12">
          <Button disabled={!isValid} type="submit" className=" w-full">
            Save
          </Button>
        </div>
      </form>
    </>
  );
}
