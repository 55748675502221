import { v4 as uuid } from 'uuid';
import { freshToken } from './freshToken.helper';
import { appLocalStorage, appSessionStorage } from './storage.helper';
import { WrapperPlatform } from '@/types/App';
import jwtDecode from 'jwt-decode';

export const needToClearStorage = () => {
  const freshToken = appLocalStorage.getItem('refreshToken');
  if (!freshToken) return false;

  const { gameUserToken } = jwtDecode<{ gameUserToken?: string }>(freshToken);
  if (!gameUserToken) return false;

  const gameUserId = new URLSearchParams(window.location.search).get('gameUserId');
  return gameUserToken !== gameUserId;
};

export type UwpUrlParams = { type: 'vuplex'; stripeKey: string; gameId: string; sessionId: string };
export type StoreUrlParams = {
  type: 'store';
  gameId: string;
  sessionId: string;
  userToken: string | null;
  gameUserId: string | null;
  platform: WrapperPlatform | null;
};

export type AppUrlParams = UwpUrlParams | StoreUrlParams;

export const readAppUrlParams = (): AppUrlParams => {
  const searchParams = new URLSearchParams(window.location.search);

  const stripeKey = searchParams.get('stripeKey');

  const gameId = searchParams.get('gameId') || appSessionStorage.getItem('gameId');
  const sessionId = searchParams.get('sessionId') || appSessionStorage.getItem('sessionId');
  if (!gameId) throw new Error('gameId is missing');

  if (stripeKey) {
    if (!sessionId) throw new Error('sessionId is missing');

    return {
      type: 'vuplex',
      stripeKey,
      gameId,
      sessionId,
    };
  }

  const userToken = searchParams.get('userToken');
  const gameUserId = searchParams.get('gameUserId');
  const platform = searchParams.get('platform') as WrapperPlatform;

  // cleanup params
  if (!platform) {
    searchParams.delete('gameId');
    searchParams.delete('sessionId');
    searchParams.delete('platform');
    searchParams.delete('gameUserId');
    searchParams.delete('userToken');

    const newUrl = `${window.location.pathname}${searchParams.toString() ? `?${searchParams.toString()}` : ''}`;

    window.history.pushState(null, '', newUrl);
  }

  return {
    type: 'store',
    gameId,
    sessionId: sessionId || uuid(),
    userToken,
    gameUserId,
    platform,
  };
};

export const initVuplexPaymentSessionStorage = (params: UwpUrlParams) => {
  const gameId = appSessionStorage.getItem('gameId') || params.gameId;
  if (!gameId) throw new Error('gameId is missing');
  appSessionStorage.setItem('gameId', gameId);

  const sessionId = appSessionStorage.getItem('sessionId') || params.sessionId;
  appSessionStorage.setItem('sessionId', sessionId);
};

export const initAppSessionStorage = (params: StoreUrlParams) => {
  const gameId = appSessionStorage.getItem('gameId') || params.gameId;
  if (!gameId) throw new Error('gameId is missing');

  appSessionStorage.setItem('gameId', gameId);

  const sessionId = appSessionStorage.getItem('sessionId') || params.sessionId;
  appSessionStorage.setItem('sessionId', sessionId);

  const platform = appSessionStorage.getItem('platform') || (params.platform as WrapperPlatform);
  appSessionStorage.setItem('platform', platform);

  const refreshToken = appLocalStorage.getItem('refreshToken') || params.userToken;
  freshToken.setTokens({ refreshToken });

  const gameUserId = appSessionStorage.getItem('gameUserId') || params.gameUserId;
  if (gameUserId) appSessionStorage.setItem('gameUserId', gameUserId);
};
