import { PaymentForm } from '@/components/Form/PaymentForm';
import { ProceedToPayment } from '@/helpers/sdk-wrapper/VuplexPaymentPage/ProceedToPayment';
import { appSessionStorage } from '@/helpers/storage.helper';
import { useStoreInfo } from '@/hooks/useStoreInfo';
import { getStripeOptions } from '@/utils/stipe.utls';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useEffect, useMemo } from 'react';
import { CartType, cartStore } from '@/store/cart.store';
import { useSnapshot } from 'valtio';
import { useNavigate } from 'react-router-dom';
import { appRoutes } from '@/helpers/routes.helper';

export function PaymentPage() {
  const navigate = useNavigate();
  const stripeSecret = appSessionStorage.getItem('stripeKey')!;
  const { platform } = useStoreInfo();
  const stripe = useMemo(() => loadStripe(platform.stripeKey), [platform.stripeKey]);
  const options = useMemo(() => getStripeOptions(stripeSecret), [stripeSecret]);
  const { products } = useSnapshot(cartStore.state) as CartType;

  const cartItems = Object.values(products);

  useEffect(() => {
    if (!cartItems.length) navigate(appRoutes.store);
  });

  if (appSessionStorage.getItem('platform') === 'Vuplex') return <ProceedToPayment />;

  return (
    <Elements stripe={stripe} options={options}>
      <div className="flex min-h-full flex-col items-center px-2 pt-4 md:pt-8">
        <div className="flex-grow-1 flex min-h-full w-full flex-col items-center">
          <PaymentForm />
        </div>
        <div className="h-4 lg:h-8" />
      </div>
    </Elements>
  );
}
