import { IndexRouteObject, RouteObject } from 'react-router-dom';
import { ProtectedRoute } from '@/components/Common/ProtectedRoute';
import {
  MyCartQuery,
  StoreInfoQuery,
  useMeQuery,
  useMyCartQuery,
  useMyRewardsQuery,
  useStoreInfoQuery,
} from '@/graphql/generated';
import { fetcherGraphql } from '@/helpers/fetch.helper';
import { freshToken } from '@/helpers/freshToken.helper';
import { queryClient } from '@/helpers/query.helper';
import { appRoutes } from '@/helpers/routes.helper';
import { LegalPage } from '@/pages/LegalPage';
import { LoginPage } from '@/pages/LoginPage';
import { StorePage } from '@/pages/StorePage';
import { CategoryPage, categoryLoader } from '@/pages/CategoryPage';
import { WelcomePage } from '@/pages/WelcomePage';
import { productOfferLoader, ProductOfferPage } from '@/pages/ProductOfferPage';
import { CartPage } from '@/pages/CartPage';
import { RegistrationPage } from '@/pages/RegistrationPage';
import { ForgotPasswordPage } from './pages/ForgotPasswordPage';
import { VideoOfferPage, videoOfferLoader } from './pages/VideoOfferPage';
import { CampaignOfferPage, campaignOfferLoader } from './pages/CampaignOfferPage';
import { AppDownloadPage, appDownloadLoader } from './pages/AppDownload';
import { TermAndConditions } from './pages/LegalPage/TermsAndConditions';
import { PrivacyAndPolicy } from './pages/LegalPage/PrivacyAndPolicy';
import { AccountSettingsPage, NameAndAge, Address, ChangePassword, Preferences } from './pages/AccountSettingsPage';
import { RewardsPage } from './pages/RewardsPage';
import { OrdersHistoryPage, orderHistoryLoader } from './pages/OrdersHistoryPage';
import { WalletPage } from './pages/Wallet';
import { ScutiTransactionsPage, scutiTransactionsLoader } from './pages/ScutiTransactions';
import { CheckoutAddressPage, CheckoutPage } from './pages/CheckoutPage';
import { PaymentPage, PaymentCompletePage } from './pages/PaymentPage';
import { PaymentWithScutisPage } from './pages/PaymentWithScutisPage';
import { OrderDetailsPage, orderDetailsLoader } from './pages/OrderDetailsPage';

import { Splash } from './components/Common/Splash';
import { appSessionStorage } from './helpers/storage.helper';
import { MainLayout } from './components/Layout/MainLayout';
import { cartStore } from './store/cart.store';
import { AboutPage } from './pages/AboutPage';
import { fetchOffersByCategories } from './hooks/useOffersByCategories';

export const initializeLoader: IndexRouteObject['loader'] = async () => {
  if (freshToken.hasRefreshToken()) {
    const [, , { myCart }] = await Promise.all([
      queryClient.ensureQueryData({
        queryFn: fetcherGraphql(useMeQuery.document),
        queryKey: useMeQuery.getKey(),
      }),
      queryClient.ensureQueryData({
        queryFn: fetcherGraphql(useMyRewardsQuery.document),
        queryKey: useMyRewardsQuery.getKey(),
      }),
      queryClient.ensureQueryData({
        queryFn: fetcherGraphql<MyCartQuery, {}>(useMyCartQuery.document),
        queryKey: useMyCartQuery.getKey(),
      }),
    ]);

    if (!cartStore.state.itemsInCart) cartStore.actions.setCart(myCart.products);
  }

  const id = appSessionStorage.getItem('gameId')!;

  const [{ gameInfo }] = await Promise.all([
    queryClient.ensureQueryData<StoreInfoQuery>({
      queryFn: fetcherGraphql(useStoreInfoQuery.document, { id }),
      queryKey: useStoreInfoQuery.getKey({ id }),
    }),
    queryClient.ensureQueryData({ queryKey: ['offersByCategories'], queryFn: fetchOffersByCategories }),
  ]);

  if (!gameInfo) throw new Error(`gameId: ${id} is not valid`);

  return null;
};

export const routing: RouteObject[] = [
  {
    loader: initializeLoader,
    errorElement: <Splash />,
    children: [
      {
        element: <MainLayout />,
        children: [
          {
            path: appRoutes.store,
            element: <StorePage />,
          },
          {
            path: appRoutes.category(),
            loader: categoryLoader(queryClient),
            element: <CategoryPage />,
          },
          {
            path: appRoutes.productOffer(),
            loader: productOfferLoader(queryClient),
            element: <ProductOfferPage />,
          },
          {
            path: appRoutes.videoOffer(),
            loader: videoOfferLoader(queryClient),
            element: <VideoOfferPage />,
          },
          {
            path: appRoutes.campaignOffer(),
            loader: campaignOfferLoader(queryClient),
            element: <CampaignOfferPage />,
          },
          {
            path: appRoutes.appDownload(),
            loader: appDownloadLoader(queryClient),
            element: <AppDownloadPage />,
          },
          {
            path: appRoutes.wallet,
            element: <ProtectedRoute needAuthorization component={<WalletPage />} />,
          },
          {
            path: appRoutes.cart,
            element: <ProtectedRoute needAuthorization component={<CartPage />} />,
          },
          {
            path: appRoutes.orderHistory(),
            loader: orderHistoryLoader(queryClient),
            element: <ProtectedRoute needAuthorization component={<OrdersHistoryPage />} />,
          },
          {
            path: appRoutes.orderDetails(),
            loader: orderDetailsLoader(queryClient),
            element: <ProtectedRoute needAuthorization component={<OrderDetailsPage />} />,
          },
          {
            path: appRoutes.transactions,
            loader: scutiTransactionsLoader(queryClient),
            element: <ProtectedRoute needAuthorization component={<ScutiTransactionsPage />} />,
          },
          {
            path: appRoutes.about,
            element: <AboutPage />,
          },
        ],
      },
      {
        element: <MainLayout hideHeader />,
        children: [
          {
            path: appRoutes.checkout,
            element: <ProtectedRoute needAuthorization component={<CheckoutPage />} />,
          },
          {
            path: appRoutes.checkoutAddress,
            element: <ProtectedRoute needAuthorization component={<CheckoutAddressPage />} />,
          },
          {
            path: appRoutes.paymentWithScutis,
            element: <ProtectedRoute needAuthorization component={<PaymentWithScutisPage />} />,
          },
          {
            path: appRoutes.paymentComplete,
            element: <ProtectedRoute needAuthorization component={<PaymentCompletePage />} />,
          },
          {
            path: appRoutes.payment,
            element: <ProtectedRoute needAuthorization component={<PaymentPage />} />,
          },
        ],
      },
      {
        path: appRoutes.rewards,
        element: <ProtectedRoute needAuthorization component={<RewardsPage />} />,
      },
      {
        path: appRoutes.welcome,
        element: <ProtectedRoute component={<WelcomePage />} />,
      },
      {
        path: appRoutes.login,
        element: <ProtectedRoute component={<LoginPage />} />,
      },
      {
        path: appRoutes.registration,
        element: <ProtectedRoute component={<RegistrationPage />} />,
      },
      {
        path: appRoutes.forgotPassword,
        element: <ProtectedRoute component={<ForgotPasswordPage />} />,
      },
      {
        path: appRoutes.legal,
        element: <LegalPage />,
        children: [
          {
            path: appRoutes.legalTermsAndConditions,
            element: <TermAndConditions />,
          },
          {
            path: appRoutes.legalPrivacyPolicy,
            element: <PrivacyAndPolicy />,
          },
        ],
      },
      {
        path: appRoutes.accountSettings,
        element: <ProtectedRoute needAuthorization component={<AccountSettingsPage />} />,
        children: [
          {
            path: appRoutes.accountSettingsNameAndAge,
            element: <NameAndAge />,
          },
          {
            path: appRoutes.accountSettingsPassword,
            element: <ChangePassword />,
          },
          {
            path: appRoutes.accountSettingsAddress,
            element: <Address />,
          },
          {
            path: appRoutes.accountSettingsPreferences,
            element: <Preferences />,
          },
        ],
      },
    ],
  },
];
